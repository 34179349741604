(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/widget-events/assets/javascripts/widget-events.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/widget-events/assets/javascripts/widget-events.js');
"use strict";


const {
  KambiWidgetEvents
} = svs.oddset_kambi.components.common.constants;
class WidgetEvents extends EventTarget {
  openExpandedStatistics(betRadarId) {
    const event = new CustomEvent(KambiWidgetEvents.SET_BETRADAR_ID, {
      detail: {
        betRadarId
      }
    });
    this.dispatchEvent(event);
  }
}
setGlobal('svs.oddset_kambi.components.widgetEvents', new WidgetEvents());

 })(window);